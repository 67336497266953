import React from "react"
import { Link } from "gatsby"
import Contact from "../components/contact"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/why.scss"
import Section from "../components/section"
import SectionSubtitle from "../components/sectionSubtitle"
import Text from "../components/text"
import Footer from "../components/footer"

const Compulsive = () => (
  <Layout>
    <SEO
      lang="it"
      title="Disturbo ossessivo-compulsivo | Stefano Maffina | Psicologo clinico | Gardone Val Trompia"
      description="Descrizione del disturbo ossessivo compulsivo di Stefano Maffina, psicologo clinico"
    />
    <div className="navbar paddings-lr">
      <div style={{ width: "1050px", margin: "0 auto", paddingLeft: "16px" }}>
        <Link to="/">
          <span>Indietro</span>
        </Link>
      </div>
    </div>
    <Section isAbout color="#f8f8f8">
      <div style={{ paddingTop: "64px" }}>
        <div className="section-subtitle">
          <h2
            data-aos="custom-fade"
            data-aos-duration="1000"
            style={{ color: "#121212" }}
          >
            <span style={{ fontWeight: 700, textDecoration: "italic" }}>
              Disturbo ossessivo compulsivo{" "}
            </span>{" "}
          </h2>
        </div>{" "}
        <div className="paddings-lr why-content">
          <p>
            E' un disturbo d’ansia caratterizzato da pensieri intrusivi che
            causano disagio, e comportamenti ripetitivi che la persona si sente
            obbligata a mettere in atto nel tentativo di controllare le emozioni
            spiacevoli.
          </p>
          <p>
            Questo disturbo comprende ossessioni (pensieri, impulsi o immagini
            ricorrenti e persistenti, vissuti come intrusivi o inappropriati, e
            cha causano ansia o disagi marcati) e compulsioni (comportamenti
            ripetitivi es. lavarsi le mani, riordinare, controllare…, o azioni
            mentali es. pregare, contare, ripetere parole mentalmente…, che la
            persona si sente obbligata a mettere in atto in risposta ad una
            ossessione).
          </p>
        </div>
      </div>
    </Section>
    <Contact />
    <Footer />
  </Layout>
)

export default Compulsive
